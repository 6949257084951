.appLayoutContentContainer {
  padding-bottom: $spacingML;
  padding-top: $spacingML;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $darkgray;
  }

  p {
    padding-top: $spacingXS;
    padding-bottom: $spacingXS;
  }

  a {
    color: $blue;
  }

  .buttonLinkContainer {
    display: flex;
    flex-direction: row;
    padding-top: $spacingML;
    padding-bottom: $spacingML;
    a {
      color: $silver;
    }

    @include respond-mobile {
      justify-content: center;
      align-items: center;
    }

    div {
      justify-content: center;
      align-items: center;
      padding-top: $spacingXS;
      padding-bottom: $spacingXS;
      padding-left: $spacingL;
      padding-right: $spacingL;
      margin-right: $spacingS;
      display: inline-flex;
      @include respond-tablet {
        padding-left: $spacingS;
        padding-right: $spacingS;
      }
      @include respond-mobile {
        padding-left: $spacingS;
        padding-right: $spacingS;
      }
      a {
        @include respond-tablet {
          font-size: $fontSizeBodylarge;
        }
        @include respond-mobile {
          font-size: $fontSizeBodysmall;
        }
      }
    }
  }

  .linkContainer {
    display: flex;
    flex-direction: column;
    padding-top: $spacingML;
    padding-bottom: $spacingML;
    div {
      padding-top: $spacingXS;
      padding-bottom: $spacingXS;
    }

    a {
      font-size: $fontSizeBodylarge;
      font-weight: bold;
    }
  }

  &.pageSectionContainer {
    padding-top: $spacingXL;
    padding-bottom: $spacingXL;
    em {
      color: $silver;
      font-style: normal;
    }
  }

  &.inlineContentContainer {
    position: relative;
    > div:first-of-type {
      margin-top: $spacingXL;
      @include respond-tablet-mobile {
        margin-top: $spacingXL;
      }
    }

    p {
      font-weight: normal;
      font-size: 20px;
      line-height: 170%;
      letter-spacing: -0.02em;
      @include respond-mobile {
        font-size: 16px;
      }
    }
    h3 {
      font-size: $fontSize32;
      font-weight: bold;
      margin-bottom: $spacingL;
    }
    h6 {
      font-weight: normal;
      font-size: 26px;
      line-height: 170%;
      letter-spacing: -0.02em;
      @include respond-mobile {
        font-size: 16px;
      }
    }

    ol {
      font-family: CircularPro;
      font-weight: normal;
      font-size: 26px;
      line-height: 150%;
      letter-spacing: -0.02em;
      padding-top: $spacingS;
      padding-bottom: $spacingS;
      @include respond-tablet {
        font-size: $fontSize24;
        line-height: 40px;
      }
      @include respond-mobile {
        font-size: $fontSizeBodylarge;
        line-height: 32px;
      }
      li {
        list-style-image: url("../../public/svg/red-bullet.svg");
        margin-left: 30px;
        padding-inline-start: 30px;
        margin-bottom: 24px;
        @include respond-mobile {
          margin-bottom: 16px;
        }
      }
    }

    .imageContainer {
      flex: 1;

      @include respond-tablet-mobile {
        height: 100%;
        width: 100%;
        position: absolute;
        left: 45%;
        transform: translate(-46%, -30%);
      }

      @include respond-mobile {
        height: 100%;
        width: 100%;
        min-height: 600px;
        transform: translate(-46%, -30%);
      }
    }

    .buttonLinkContainer {
      @include respond-tablet-mobile {
        justify-content: center;
        align-items: center;
      }
    }

    .textContainer {
      @include respond-tablet-mobile {
        flex: 1;
        margin-top: $spacingXL;
        padding-top: $spacingM;
      }

      background-color: $silver;

      h3 {
        font-size: $fontSize32;
        font-weight: bold;
      }

      p {
        font-size: $fontSize24;
        color: $darkgray;

        @include respond-mobile {
          font-size: $fontSizeBodylarge;
        }
      }

      ul {
        margin-top: $spacingL;
        li {
          margin-bottom: 0;
          list-style-image: url("/svg/red-bullet.svg");
          font-weight: bold;
          color: $darkgray;
        }
      }
    }

    .gridContent {
      z-index: 10;
      @include respond-tablet-mobile {
        background-color: $silver;
      }
      @include respond-tablet {
        margin-bottom: 256px;
      }
      @include respond-mobile {
        margin-bottom: $spacingXXXXL;
      }
    }
  }

  &.headerContainer {
    min-height: 340px;
    padding-bottom: 0px;
    margin-bottom: 0px;

    @include respond-tablet-mobile {
      padding-top: 0px;
      margin-bottom: $spacingXXXXL;
    }
    @include respond-mobile {
      margin-bottom: $spacingXXL;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
      color: $silver;
    }
    h4,
    p {
      color: $silver;
      font-weight: bold;
      font-size: 20px;
      line-height: 130%;
      letter-spacing: -0.02em;
      @include respond-mobile {
        font-size: 16px;
      }
    }
    em {
      color: $red;
      font-style: normal;
    }

    .imageContainer {
      height: 100%;
      overflow: hidden;
      img {
        object-position: center top;
      }
      @include respond-tablet-mobile {
        display: none;
      }
    }

    .buttonLinkContainer {
      @include respond-mobile {
        width: 100%;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
