@import "variables.scss"; @import "mixins.scss";
.rowContainer {
  border-bottom: 1px solid black;
  min-width: 800px;

  &:not(:first-child) {
    &:hover {
      cursor: pointer;
    }
  }

  .arrowIcon {
    transition: transform 0.2s linear;

    &.isOpened {
      transform: rotate(180deg);
    }
  }

  .rowData {
    flex: 1;
    display: flex;
    padding-top: $spacingM;
    padding-bottom: $spacingM;

    .cell {
      display: flex;
      flex: 1;
      padding-right: $spacingXS;
      align-items: center;
    }

    :last-child {
      &.cell {
        justify-content: space-between;
        flex: 0.6;
      }
    }
  }
}
