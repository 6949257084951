@import "variables.scss"; @import "mixins.scss";
.chartContainer {
  width: 100%;
  height: 150px;
}

.tooltipContainer {
  background-color: $yellow;
  padding: $spacingS;
  border-radius: $spacingXS;

  .percentage {
    font-size: $fontSizeBody;
    font-weight: bold;
    line-height: 22px;
  }

  .date {
    font-size: $fontSizeSmall;
    line-height: 14px;
  }
}
